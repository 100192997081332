import { useEffect, useState } from "react"
import { FirebaseAuth } from "../FirebaseApp"
import { AuthState } from "../util/Types"

export default function useAuth() {
    const [user, setUser] = useState<AuthState>('loading')
    useEffect(() => {
        FirebaseAuth?.onAuthStateChanged(u => setUser(u || 'unauthenticated'))
    }, [])
    return user
}