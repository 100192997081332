import { Alert, AlertColor, Snackbar } from "@mui/material"

export default function BasicSnackbar(props: {
    open: boolean,
    onClose: () => any,
    color: AlertColor,
    message: string
}) {
    return <Snackbar anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }} open={props.open} autoHideDuration={3000} onClose={props.onClose}>
        <Alert onClose={props.onClose} color={props.color} variant='filled'>{props.message}</Alert>
    </Snackbar>
}