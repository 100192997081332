import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid2 } from "@mui/material";
import {ref, uploadString} from 'firebase/storage'
import { FirebaseFirestore, FirebaseStorage } from "../FirebaseApp";
import { useEffect, useState } from "react";
import If, { IfLet } from "../util/If";
import { collection, doc, updateDoc } from "firebase/firestore";
import { Job, jobState, JobState, UploadState } from "../util/Types";
import heic2any from "heic2any";
import FirebaseStorageFile from "../util/FirebaseStorageFile";
import UploadProgressDialog from "./UploadProgressDialog";
import PhotoActionsDialog from "./PhotoActionsDialog";
import { HideImage } from "@mui/icons-material";
import VisuallyHiddenInput from "../util/VisuallyHiddenInput";
import BasicSnackbar from "../util/BasicSnackbar";

function compress(bitmap: ImageBitmap): string {
    let canvas = document.createElement('canvas')
    let ctx = canvas.getContext('2d')!
    canvas.width = bitmap.width
    canvas.height = bitmap.height
    ctx.drawImage(bitmap, 0, 0)
    let dataURL = canvas.toDataURL('image/jpeg', 0.75)
    bitmap.close()
    return dataURL
}

async function uploadFiles(job: Job, files: FileList) {
    if (!FirebaseFirestore || !FirebaseStorage) return
    let idx = 1
    let array = Array.from(files)
    let count = array.length
    let names: string[] = []
    for (let file of array) {
        window.postMessage({ upload: { current: idx, count }})
        let bitmap: ImageBitmap
        if (file.name.toLowerCase().endsWith('heic')) {
            let output = await heic2any({
                blob: file,
                toType: 'image/png'
            })
            bitmap = await createImageBitmap('length' in output ? output[0] : output)
        } else {
            bitmap = await createImageBitmap(file)
        }
        let dataURL = compress(bitmap)
        let name = `${job.id}_${Date.now()}.jpg`
        names.push(name)
        window.postMessage({ upload: { status: 'Uploading', current: idx, count } })
        await uploadString(ref(FirebaseStorage, `job_photos/${name}`), dataURL, 'data_url', { contentType: 'image/jpeg' })
        idx++
    }
    window.postMessage({ upload: 'finishing' })
    await updateDoc(doc(collection(FirebaseFirestore, 'jobs'), job.id), { attachments: [...job.attachments, ...names] })
    window.postMessage({ upload: 'done' })
}

export default function PhotosDialog(props: {open: boolean, job: Job, onClose: () => void}) {
    const [uploadState, setUploadState] = useState<UploadState | null>(null)
    useEffect(() => {
        function updateUploadState(ev: MessageEvent<any>) {
            if (typeof ev.data === 'object' && 'upload' in ev.data) {
                if (ev.data.upload === 'done')
                    setUploadState(null)
                else
                    setUploadState(ev.data.upload as UploadState)
                }
        }
        window.addEventListener('message', updateUploadState)
        return () => window.removeEventListener('message', updateUploadState)
    }, [setUploadState])
    const [selectedImage, setSelectedImage] = useState<{name: string, url: string} | null>(null)
    const [uploadSuccess, setUploadSuccess] = useState(false)
    const [uploadFailure, setUploadFailure] = useState(false)
    return <Dialog open={props.open} fullScreen scroll='paper'>
        <IfLet value={uploadState}>{state =>
            <UploadProgressDialog state={state}/>
        }</IfLet>
        <DialogTitle>
            Job Photos
        </DialogTitle>
        <DialogContent dividers>
            <PhotoActionsDialog job={props.job} selectedImage={selectedImage} onClose={() => setSelectedImage(null)}/>
            <If condition={props.job.attachments.length === 0}>
                <HideImage sx={{fontSize: '8rem', opacity: 0.2, position: 'fixed', top: '50%', left: '50%', transform: 'translateX(-50%) translateY(-50%)'}}/>
            </If>
            <Grid2 container spacing={1}>
                {props.job.attachments.map(i => <Grid2 key={i} size={{ xs: 6, sm: 4, md: 3 }}>
                    <FirebaseStorageFile path={`job_photos/${i}`}>{url =>
                        <Button onClick={() => setSelectedImage({ name: i, url })}>
                            <img alt={i} style={{ width: '100%' }} src={url}></img>
                        </Button>
                    }</FirebaseStorageFile>
                </Grid2>)}
            </Grid2>
        </DialogContent>
        <DialogActions>
            <Button disabled={jobState(props.job) === JobState.CLOSED} component="label" role={undefined}>
                Upload
                <VisuallyHiddenInput accept="image/*" type="file" multiple onChange={
                    e => e.target.files && uploadFiles(props.job, e.target.files)
                    .then(
                        () => setUploadSuccess(true),
                        () => {
                            setUploadState(null)
                            setUploadFailure(true)
                        })
                    }/>    
            </Button>
            <Button variant='contained' color='success' onClick={props.onClose}>Close</Button>
        </DialogActions>
            <BasicSnackbar open={uploadSuccess} onClose={() => setUploadSuccess(false)} color='success' message='Photos uploaded successfully.'/>
            <BasicSnackbar open={uploadFailure} onClose={() => setUploadFailure(false)} color='error' message='An error occurred while uploading photos.'/>
    </Dialog>
}