import './App.css';
import { Alert, AlertTitle, BottomNavigation, BottomNavigationAction, Box, Button, Card, CardActionArea, CardActions, CardContent, Chip, Collapse, Divider, Fab, Fade, Grow, Paper, Slide, Stack, Typography, useTheme } from '@mui/material';
import { Add, ChevronRight, CopyAll, DoneAll, Email, Forest, Person, Phone, Photo, PinDrop, PriorityHigh, RequestQuote, Today } from '@mui/icons-material';
import HSpacer from './util/HSpacer';
import If, { IfLet } from './util/If';
import { Job, JobState, jobState } from './util/Types';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import JobDialog from './JobDialog';
import FullscreenProgress from './util/FullscreenProgress';
import useFirestore from './util/useFirestore';
import SearchContext from './filter/SearchContext';
import { defaultFilter, processFilters, processSearch } from './filter/SearchUtil';
import Version from './util/Version';
import BasicSnackbar from './util/BasicSnackbar';

const bull = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
  >
    •
  </Box>
)

function JobCard(props: {job: Job, onClick: (arg0: Job) => void}) {
  let theme = useTheme()
  let [copyNotification, setCopyNotification] = useState(false)
  let copyAddress = useCallback(() => {
    let street = [props.job.address.line1, props.job.address.line2].filter(l => l.length > 0).join(' ')
    let cityStateZip = [props.job.address.city, props.job.address.state, props.job.address.zip].filter(l => l.length > 0).join(', ')
    navigator.clipboard.writeText([street, cityStateZip].filter(l => l.length > 0).join(', ')).then(() => setCopyNotification(true))
  }, [props.job, setCopyNotification])
  const hasPhoneNumber = useMemo(() => props.job.phone.length > 0, [props.job])
  const hasEmail = useMemo(() => props.job.email.length > 0, [props.job])
  const hasAddress = useMemo(() => [props.job.address.line1, props.job.address.city, props.job.address.state].every(l => l.length > 0), [props.job])
  return <Card elevation={2}>
    <CardActionArea onClick={() => props.onClick(props.job)}>
      <CardContent>
        <Stack direction='row'>
          <Stack direction='column' spacing={1}>
            <Stack direction='row' spacing={1} sx={{ justifyContent: 'flex-start', alignItems: 'center' }}>
              <If condition={props.job.priority === true}>
                <PriorityHigh fontSize='small' color='error' />
              </If>
              <Typography variant='h6'>
                <If condition={props.job.customerName.length > 0}>
                  {props.job.customerName}
                </If>
                <If condition={props.job.customerName.length === 0}>
                  <Typography sx={{...theme.typography.subtitle1, fontWeight: 'bold', display: 'inline', color: theme.palette.text.secondary}}>No name</Typography>
                </If>
                <If condition={props.job.estimatedPrice.length > 0}><>
                  {bull} {isNaN(parseInt(props.job.estimatedPrice)) ? props.job.estimatedPrice : `$${props.job.estimatedPrice}`}
                </></If>
                <IfLet value={props.job.estimatedHours}>{h => <>
                  {bull} {h}hrs
                </>}</IfLet>
              </Typography>
            </Stack>
            <Stack direction="row" spacing={1} sx={{ ...theme.typography.body2, justifyContent: 'flex-start', alignItems: 'center', flexWrap: 'wrap' }} useFlexGap divider={<Divider orientation='vertical' variant='fullWidth' flexItem />}>
              {hasAddress && <Stack direction="row" spacing={1} sx={{ justifyContent: 'flex-start', alignItems: 'center' }}>
                <PinDrop fontSize='small' />
                <Typography fontSize='inherit'>{props.job.address.city}</Typography>
              </Stack>}
              {props.job.contactName && <Stack direction="row" spacing={1} sx={{ justifyContent: 'flex-start', alignItems: 'center' }}>
                <Person fontSize='small' />
                <Typography fontSize='inherit'>{props.job.contactName}</Typography>
              </Stack>}
              {props.job.attachments.length > 0 && <Stack direction="row" spacing={1} sx={{ justifyContent: 'flex-start', alignItems: 'center' }}>
                <Photo fontSize='small' />
                <Typography fontSize='inherit'>{props.job.attachments.length}</Typography>
              </Stack>}
              <Stack direction="row" spacing={1} sx={{ justifyContent: 'flex-start', alignItems: 'center' }}>
                <Today fontSize='small' />
                <Typography fontSize='inherit'>{new Date(props.job.createdAt).toLocaleString(undefined, {
                  month: 'numeric',
                  day: 'numeric',
                  year: 'numeric',
                  hour: 'numeric',
                  minute: '2-digit',
                  hour12: true
                })}</Typography>
              </Stack>
            </Stack>
            <If condition={!!props.job.notes}>
              <Typography color='textSecondary' fontStyle='italic'>
                {props.job.notes}
              </Typography>
            </If>
          </Stack>
          <HSpacer />
          <ChevronRight fontSize='medium' sx={{ my: 'auto' }} />
        </Stack>
      </CardContent>
    </CardActionArea>
    <If condition={hasPhoneNumber || hasEmail || hasAddress}>
      <Divider/>
      <CardActions>
        <If condition={hasPhoneNumber}>
          <Button href={`tel:+1${props.job.phone.trim().replaceAll('-', '')}`} size='small' startIcon={<Phone />}>Call</Button>
        </If>
        <If condition={hasEmail}>
          <Button href={`mailto:${props.job.email}`} size='small' startIcon={<Email />}>Email</Button>
        </If>
        <If condition={hasAddress}>
          <Button onClick={copyAddress} size='small' startIcon={<CopyAll />}>Address</Button>
        </If>
      </CardActions>
    </If>
    <BasicSnackbar open={copyNotification} onClose={() => setCopyNotification(false)} color='success' message='Address copied to clipboard.'/>
  </Card>
}

function JobSection(props: {onSelect: (p0: Job) => void, jobs: Job[], totalCount: number, defaultExpanded?: boolean, title: string}) {
  const filtersActive = useMemo(() => props.jobs.length !== props.totalCount, [props.jobs, props.totalCount])
  return <Stack direction='column' spacing={1}>
    <Stack direction='row' spacing={2} sx={{ alignItems: 'center', py: 1 }}>
      <Chip color={filtersActive ? 'info' : 'default'} label={filtersActive ? `${props.jobs.length}/${props.totalCount}` : props.jobs.length} />
      <Typography variant='h5'>{props.title}</Typography>
    </Stack>
    {props.jobs.map(j => <JobCard key={j.id} job={j} onClick={() => props.onSelect(j)} />)}
    
    <Fade unmountOnExit in={props.jobs.length === 0}>
      <Alert severity='info'>No jobs found.</Alert>
    </Fade>
  </Stack>
}

function getSavedTab(): JobState {
  let value = localStorage.getItem('ats_tab')
  let parsed = parseInt(value ?? "")
  if (![0, 1, 2].includes(parsed))
      return JobState.ACTIVE
  return parsed
}

function stateName(state: JobState): string {
  switch (state) {
    case JobState.BID: return "Bids"
    case JobState.ACTIVE: return "Active"
    case JobState.CLOSED: return "Closed"
  }
}

function App() {
  const search = useContext(SearchContext)
  const [currentJob, setCurrentJob] = useState<Job | null>(null)
  const __jobs = useFirestore<Job>('jobs')
  const _jobs = useMemo(() => __jobs === null || 'code' in __jobs ? null : __jobs, [__jobs])
  const error = useMemo(() => __jobs && 'code' in __jobs ? __jobs : null, [__jobs])
  const results: Job[] | null = useMemo(() => _jobs ? processSearch(processFilters(_jobs, search.filters), search.searchQuery.trim()) : null, [_jobs, search.filters, search.searchQuery])
  const filtersActive = useMemo(() => _jobs?.length !== results?.length, [_jobs, results])
  const [tab, setTab] = useState<JobState>(getSavedTab())
  useEffect(() => {
    setCurrentJob(current => current && _jobs ? _jobs.find(j => j.id === current.id) || null : null)
  }, [setCurrentJob, _jobs])
  useEffect(() => localStorage.setItem('ats_tab', tab.toString()), [tab])
  const jobsCount = useMemo(() => _jobs?.filter(j => jobState(j) === tab).length || 0, [_jobs, tab])
  const selectedJobs = useMemo(() => results?.filter(j => jobState(j) === tab), [results, tab])
  const sectionTitle = useMemo(() => stateName(tab), [tab])
  return <>
    <IfLet value={error}>{e => <>
      <Alert sx={{ mb: 1 }} severity='error' variant='filled' action={<Button color='inherit' size='small' onClick={() => { window.location.reload() }}>Reload Page</Button>}>
        <AlertTitle>Fatal Error</AlertTitle>
        A fatal error occurred while loading jobs: {JSON.stringify(e)}
      </Alert>
    </>}</IfLet>
    <If condition={results === null}>
      <FullscreenProgress/>
    </If>
    <IfLet value={selectedJobs}>{jobs =>
      <>
        <Collapse in={filtersActive}>
          <Grow in={filtersActive}>
            <Alert sx={{mb: 1}} severity='info' variant='filled' action={<Button color='inherit' size='small' onClick={() => { search.setSearchQuery(''); search.setFilters(defaultFilter) }}>Reset</Button>}>
              <AlertTitle>Showing Only Matching Results</AlertTitle>
              The jobs below are the results of your search query (sorted by closest match) and/or filters. Tap Reset to clear all filters and see all jobs.
            </Alert>
          </Grow>
        </Collapse>
        <JobSection title={sectionTitle} totalCount={jobsCount} jobs={jobs} defaultExpanded onSelect={setCurrentJob} />
        <If condition={_jobs !== null}>
          <Version pt={2} />
        </If>
        <JobDialog job={currentJob} onClose={() => setCurrentJob(null)} />
      </>
    }</IfLet>
    <Grow unmountOnExit in={results !== null}>
      <Fab sx={{ position: 'fixed', bottom: t => t.spacing(10), right: t => t.spacing(3) }} onClick={() => setCurrentJob({
        id: Date.now().toString(36).toUpperCase(),
        active: true, // TODO: deprecated
        state: JobState.BID,
        priority: false,
        customerName: '',
        contactName: '',
        phone: '',
        email: '',
        address: {
          line1: '',
          line2: '',
          city: '',
          state: '',
          zip: ''
        },
        attachments: [],
        notes: '',
        estimatedHours: null,
        estimatedPrice: '',
        complexity: 0,
        equipment: [],
        createdAt: Date.now()
      })
      } variant='extended' color='atsGreen'>
        <Add sx={{ mr: 1 }} /> Job
      </Fab>
    </Grow>
    <Slide direction='up' unmountOnExit in={results !== null}>
      <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
        <BottomNavigation showLabels value={tab} onChange={(_, newValue) => setTab(newValue)}>
          <BottomNavigationAction href='#' value={JobState.BID} label={stateName(JobState.BID)} icon={<RequestQuote />} />
          <BottomNavigationAction href='#' value={JobState.ACTIVE} label={stateName(JobState.ACTIVE)} icon={<Forest />} />
          <BottomNavigationAction href='#' value={JobState.CLOSED} label={stateName(JobState.CLOSED)} icon={<DoneAll />} />
        </BottomNavigation>
      </Paper>
    </Slide>
  </>
}

export default App;
