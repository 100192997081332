import { ChevronRight } from "@mui/icons-material";
import { Avatar, Card, CardActions, CardContent, Divider, Skeleton, Stack, Typography } from "@mui/material";
import HSpacer from "./HSpacer";

function LoadingCard() {
    return <Card elevation={2}>
        <CardContent>
            <Stack direction='row'>
                <Stack direction='column' spacing={1} sx={{width: '75%'}}>
                    <Typography variant="h6">
                        <Skeleton height="1lh" />
                    </Typography>
                    <Typography variant="body2">
                        <Skeleton height="2lh" />
                    </Typography>
                    <Skeleton height="3lh"/>
                </Stack>
                <HSpacer />
                <ChevronRight fontSize='medium' sx={{ my: 'auto' }} />
            </Stack>
        </CardContent>
        <Divider />
        <CardActions>
            <Skeleton width="25%"/>
        </CardActions>
    </Card>
}

export default function FullscreenProgress() {
    return <Stack direction='column' spacing={1}>
        <Stack direction='row' spacing={2} sx={{ alignItems: 'center', py: 1 }}>
            <Skeleton variant="circular"><Avatar/></Skeleton>
            <Skeleton><Typography variant='h5'>Active Jobs</Typography></Skeleton>
        </Stack>
        {[1, 2, 3].map(idx => <LoadingCard key={idx} />)}
    </Stack>
}