import { browserLocalPersistence, signInWithEmailAndPassword } from 'firebase/auth'
import { Alert, AlertTitle, Container, Grow, Stack, TextField } from "@mui/material";
import { useCallback, useState } from "react";
import { LoadingButton } from "@mui/lab";
import HSpacer from "../util/HSpacer";
import { Login as LoginIcon } from '@mui/icons-material'
import { FirebaseAuth } from '../FirebaseApp';
import Version from '../util/Version';
import { FirebaseError } from 'firebase/app';

export default function LoginForm() {
    const [isLoading, setLoading] = useState(false)
    const [error, setError] = useState<FirebaseError | null>(null)
    const login = useCallback(() => {
        if (!FirebaseAuth) return
        let email = (document.getElementById('email') as HTMLInputElement).value + '@' + FirebaseAuth.config.authDomain
        let password = (document.getElementById('password') as HTMLInputElement).value
        setLoading(true)
        FirebaseAuth.setPersistence(browserLocalPersistence).then(() => FirebaseAuth && signInWithEmailAndPassword(FirebaseAuth, email, password).catch(e => setError(e as FirebaseError)).finally(() => setLoading(false)))
    }, [])
    return (
        <Container sx={{ padding: 0 }} maxWidth="sm">
            <Stack spacing={2} sx={{ px: 6, position: 'relative', transform: 'translateY(50%)' }}>
                <Grow in={error !== null} unmountOnExit>
                    <Alert severity="error" onClose={() => setError(null)}>
                        <AlertTitle>Error</AlertTitle>
                        {error && error.code !== 'auth/invalid-credential' ? error.code : 'Incorrect email or password - please try again.'}
                    </Alert>
                </Grow>
                <TextField disabled={isLoading} id="email" type="text" autoComplete="username" label="Username" />
                <TextField disabled={isLoading} id="password" type="password" autoComplete="current-password" label="Password" />
                <Stack direction="row">
                    <HSpacer />
                    <LoadingButton color='atsGreen' onClick={login} loading={isLoading} loadingPosition="start" startIcon={<LoginIcon />} size="large" variant="contained">{isLoading ? 'Logging in...' : 'Login'}</LoadingButton>
                    <HSpacer />
                </Stack>
                <Version />
            </Stack>
            
        </Container>
    )
}