import { styled, alpha } from '@mui/material/styles'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import InputBase from '@mui/material/InputBase'
import SearchIcon from '@mui/icons-material/Search'
import useAuth from './hooks/useAuth'
import If from './util/If'
import { FilterAlt, Logout } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import { useContext, useState } from 'react'
import { signOut } from 'firebase/auth'
import { FirebaseAuth } from './FirebaseApp'
import { FiltersDialog } from './filter/FiltersDialog'
import SearchContext from './filter/SearchContext'

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
    },
}))

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}))

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    width: '100%',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
}))

export default function SearchAppBar() {
    const auth = useAuth()
    const [menuAnch, setMenuAnch] = useState<HTMLElement | null>(null)
    const menuOpen = Boolean(menuAnch)
    const search = useContext(SearchContext)
    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="fixed" color='atsGreen'>
                <Toolbar>
                    <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block', cursor: 'default', userSelect: 'none' } }}
                    >
                        Alvin Tree Service
                    </Typography>
                    <If condition={typeof auth === 'object' && window.location.pathname === '/'}>
                        <Search>
                            <SearchIconWrapper>
                                <SearchIcon />
                            </SearchIconWrapper>
                            <StyledInputBase
                                placeholder="Search…"
                                inputProps={{ 'aria-label': 'search' }}
                                value={search.searchQuery}
                                onChange={e => search.setSearchQuery(e.target.value)}
                            />
                        </Search>
                        <IconButton sx={{ml: 2}} color='inherit' onClick={e => setMenuAnch(e.currentTarget)}>
                            <FilterAlt/>
                        </IconButton>
                        <IconButton sx={{ ml: 2 }} color='inherit' onClick={() => FirebaseAuth && signOut(FirebaseAuth)}>
                            <Logout />
                        </IconButton>
                        <FiltersDialog open={menuOpen} onClose={() => setMenuAnch(null)}/>
                    </If>
                </Toolbar>
            </AppBar>
            <Toolbar/>
        </Box>
    )
}