import { Button, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, Divider, FormControlLabel, FormLabel, Radio, RadioGroup, Stack, Theme, Typography, useMediaQuery } from "@mui/material";
import { useCallback, useContext, useMemo } from "react";
import SearchContext from "./SearchContext";
import useFirestore from "../util/useFirestore";
import { Job } from "../util/Types";
import If, { IfLet } from "../util/If";
import { FilterType, defaultFilter } from "./SearchUtil";

const divider = <Divider sx={{ my: 1 }} />

type ArrayOfType<T, V> = keyof { [P in keyof T as T[P] extends V[] ? P : never]: P } // { [P in keyof T]-?: T[P] extends K[] ? P : never }[keyof T]

export function FiltersDialog(props: Pick<DialogProps, 'open' | 'onClose'>) {
    const fullScreen = useMediaQuery(t => (t as Theme).breakpoints.down('sm'))
    const {filters, setFilters} = useContext(SearchContext)
    const modifyFilter = useCallback((newValues: Partial<FilterType>) => setFilters(current => ({...current, ...newValues})), [setFilters])
    const modifyArray = useCallback(<T extends {}>(value: T, included: boolean, array: ArrayOfType<FilterType, T>) => {
        setFilters(current => {
            let copy: FilterType = { ...current }
            let arr: FilterType[typeof array] = (copy[array] as any[]).filter(i => i !== value) as FilterType[ArrayOfType<FilterType, T>]
            if (included)
            (arr as any[]).push(value)
            copy[array] = arr
            return copy
        })
    }, [setFilters])
    const jobs = useFirestore<Job>('jobs')
    const cities: string[] | null = useMemo(() => jobs ? [...new Set(jobs.map(j => j.address.city.trim().toLowerCase()))].filter(c => c.length > 0).sort() : null, [jobs])

    return <Dialog fullScreen={fullScreen} maxWidth='sm' fullWidth scroll='paper' sx={{ userSelect: 'none', cursor: 'default' }} open={props.open} onClose={props.onClose}>
        <DialogTitle>Sort & Filter</DialogTitle>
        <DialogContent dividers>
            <Stack direction='column'>
                <FormLabel>Sort by</FormLabel>
                <RadioGroup value={filters.sortBy}>
                    <FormControlLabel value="date" control={<Radio onChange={() => modifyFilter({ sortBy: 'date' })} />} label="Date created" />
                    <FormControlLabel value="price" control={<Radio onChange={() => modifyFilter({ sortBy: 'price' })} />} label="Estimated price" />
                    <FormControlLabel value="hours" control={<Radio onChange={() => modifyFilter({ sortBy: 'hours' })} />} label="Estimated hours" />
                    <FormControlLabel value="city" control={<Radio onChange={() => modifyFilter({ sortBy: 'city' })} />} label="City (alphabetical)" />
                </RadioGroup>
                <FormLabel>Direction</FormLabel>
                <RadioGroup row value={filters.sortDir}>
                    <FormControlLabel value="asc" control={<Radio onChange={() => modifyFilter({ sortDir: 'asc' })} />} label={filters.sortBy === 'date' ? "Oldest first" : filters.sortBy === 'city' ? "A - Z" : "Ascending"} />
                    <FormControlLabel value="desc" control={<Radio onChange={() => modifyFilter({ sortDir: 'desc' })} />} label={filters.sortBy === 'date' ? "Newest first" : filters.sortBy === 'city' ? "Z - A" : "Descending"} />
                </RadioGroup>
                {divider}

                <FormLabel>City</FormLabel>
                <FormControlLabel control={<Checkbox checked={filters.cities.includes('')} onChange={e => modifyArray('', e.currentTarget.checked, 'cities')} />} label={<Typography fontStyle='italic' color="text.secondary">Not set</Typography>} />
                <IfLet value={cities}>{cities => cities.map(city => <FormControlLabel key={city} control={<Checkbox checked={filters.cities.includes(city)} onChange={e => modifyArray(city, e.currentTarget.checked, 'cities')} />} label={city} />)}</IfLet>
                <If condition={cities === null}>
                    <CircularProgress size='3rem'/>
                </If>
                {divider}

                <FormLabel>Complexity</FormLabel>
                <FormControlLabel control={<Checkbox checked={filters.complexities.includes(0)} onChange={e => modifyArray(0, e.currentTarget.checked, 'complexities')} />} label={<Typography fontStyle='italic' color="text.secondary">Not set</Typography>} />
                <FormControlLabel control={<Checkbox checked={filters.complexities.includes(1)} onChange={e => modifyArray(1, e.currentTarget.checked, 'complexities')} />} label="Cake" />
                <FormControlLabel control={<Checkbox checked={filters.complexities.includes(2)} onChange={e => modifyArray(2, e.currentTarget.checked, 'complexities')} />} label="Easy" />
                <FormControlLabel control={<Checkbox checked={filters.complexities.includes(3)} onChange={e => modifyArray(3, e.currentTarget.checked, 'complexities')} />} label="Medium" />
                <FormControlLabel control={<Checkbox checked={filters.complexities.includes(4)} onChange={e => modifyArray(4, e.currentTarget.checked, 'complexities')} />} label="Hard" />
                <FormControlLabel control={<Checkbox checked={filters.complexities.includes(5)} onChange={e => modifyArray(5, e.currentTarget.checked, 'complexities')} />} label="Expert" />
                {divider}

                <FormLabel>Attachments</FormLabel>
                <FormControlLabel control={<Checkbox checked={filters.attachments.includes(false)} onChange={e => modifyArray(false, e.currentTarget.checked, 'attachments')} />} label="No attachments" />
                <FormControlLabel control={<Checkbox checked={filters.attachments.includes(true)} onChange={e => modifyArray(true, e.currentTarget.checked, 'attachments')} />} label="Has attachments" />
                {divider}

                <FormLabel>Priority</FormLabel>
                <FormControlLabel control={<Checkbox checked={filters.priority.includes(false)} onChange={e => modifyArray(false, e.currentTarget.checked, 'priority')} />} label="Non-priority" />
                <FormControlLabel control={<Checkbox checked={filters.priority.includes(true)} onChange={e => modifyArray(true, e.currentTarget.checked, 'priority')} />} label="Priority" />
            </Stack>
        </DialogContent>
        <DialogActions>
            <Button color='error' onClick={() => setFilters(defaultFilter)}>Reset</Button>
            <Button variant='contained' color='success' onClick={() => props.onClose?.({}, 'backdropClick')}>Done</Button>
        </DialogActions>
    </Dialog>
}