import { onSnapshot, collection, Unsubscribe } from "firebase/firestore"
import { useState, useEffect } from "react"
import { FirebaseFirestore } from "../FirebaseApp"

const defaultUnsub: Unsubscribe = () => { }

export default function useFirestore<T>(collectionName: string): T[] | null {
    const [data, setData] = useState<T[] | null>(null)
    useEffect(() => (FirebaseFirestore && onSnapshot(collection(FirebaseFirestore, collectionName), snap => {
        setData(snap.docs.map(d => d.data() as T))
    })) || defaultUnsub, [collectionName])
    return data
}