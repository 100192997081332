import { Dialog, DialogTitle, Typography, DialogContent, LinearProgress } from "@mui/material";
import { UploadState } from "../util/Types";

export default function UploadProgressDialog({state}: {state: UploadState}) {
    return <Dialog open fullWidth maxWidth="sm">
        <DialogTitle>
            Uploading...
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>Don't refresh or close this window.</Typography>
        </DialogTitle>
        <DialogContent dividers>
            <LinearProgress variant={state === 'finishing' ? 'indeterminate' : 'determinate'} value={state === 'finishing' ? undefined : 100 * (state.current - 1) / state.count} />
            <Typography variant="body2" sx={{ mt: 1 }}>{state === 'finishing' ? 'Wrapping up...' : `Processing file ${state.current} of ${state.count}.`}</Typography>
        </DialogContent>
    </Dialog>
}