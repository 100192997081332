import { getDownloadURL, ref } from "firebase/storage"
import { ReactNode, useState, useEffect } from "react"
import { FirebaseStorage } from "../FirebaseApp"

export default function FirebaseStorageFile(props: { path: string, children: (p0: string) => ReactNode }) {
    const [url, setUrl] = useState<string | null>(null)
    useEffect(() => {
        FirebaseStorage && getDownloadURL(ref(FirebaseStorage, props.path)).then(setUrl, () => setUrl('/not-found.jpg'))
    }, [props.path, setUrl])
    return <>{url && props.children(url)}</>
}