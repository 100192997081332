import { Context, createContext, Dispatch, SetStateAction } from "react"
import { FilterType, defaultFilter } from "./SearchUtil"

const SearchContext: Context<{
    searchQuery: string,
    setSearchQuery: Dispatch<SetStateAction<string>>,
    filters: FilterType,
    setFilters: Dispatch<SetStateAction<FilterType>>
}> = createContext({
    searchQuery: '',
    setSearchQuery: (() => { }) as Dispatch<SetStateAction<string>>,
    filters: defaultFilter,
    setFilters: (() => { }) as Dispatch<SetStateAction<FilterType>>
})

export default SearchContext