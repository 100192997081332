// Import the functions you need from the SDKs you need
import { FirebaseError, initializeApp } from "firebase/app";
import { Auth, getAuth } from "firebase/auth";
import { Firestore, getFirestore } from "firebase/firestore";
import { FirebaseStorage as FBStorage, getStorage } from "firebase/storage";

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: process.env.REACT_APP_ATS_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_ATS_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_ATS_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_ATS_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_ATS_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_ATS_FIREBASE_APP_ID
};

// Initialize Firebase
const app = initializeApp(firebaseConfig)

let firestore: Firestore | null = null
let auth: Auth | null = null
let storage: FBStorage | null = null
let errors: { auth?: FirebaseError, firestore?: FirebaseError, storage?: FirebaseError } = {}
try {
    auth = getAuth(app)
} catch (e) {
    errors.auth = e as FirebaseError
}
try {
    storage = getStorage(app)
} catch (e) {
    errors.storage = e as FirebaseError
}
try {
    firestore = getFirestore(app)
} catch (e) {
    errors.firestore = e as FirebaseError
}

export const FirebaseErrors = errors.auth || errors.firestore || errors.storage ? errors : null
export const FirebaseFirestore = firestore
export const FirebaseAuth = auth
export const FirebaseStorage = storage
