import Fuse from "fuse.js"
import { Job } from "../util/Types"

export type FilterType = {
    sortBy: 'date' | 'price' | 'hours' | 'city',
    sortDir: 'asc' | 'desc',
    cities: string[],
    complexities: number[],
    attachments: boolean[],
    priority: boolean[]
}

export const defaultFilter: FilterType = {
    sortBy: 'date',
    sortDir: 'asc',
    cities: [],
    complexities: [],
    attachments: [],
    priority: []
}

export function processFilters(jobs: Job[], filters: FilterType): Job[] {
    return jobs.filter(j => {
        let output = true
        if (filters.cities.length > 0)
            output &&= filters.cities.includes(j.address.city.toLowerCase())
        if (filters.complexities.length > 0)
            output &&= filters.complexities.includes(j.complexity)
        if (filters.attachments.length > 0)
            output &&= filters.attachments.includes(j.attachments.length > 0)
        if (filters.priority.length > 0)
            output &&= filters.priority.includes(j.priority)
        return output
    })
    .sort((lhs, rhs) => {
        let factor = filters.sortDir === 'asc' ? 1 : -1
        let sortValue: number
        switch (filters.sortBy) {
            case 'city': sortValue = lhs.address.city.localeCompare(rhs.address.city); break
            case 'date': sortValue = lhs.createdAt - rhs.createdAt; break
            case 'hours': sortValue = (lhs.estimatedHours || 0) - (rhs.estimatedHours || 0); break
            case 'price': sortValue = ((parseInt(lhs.estimatedPrice) || 0) - (parseInt(rhs.estimatedPrice) || 0)); break
        }
        return factor * sortValue
    })
}

export function processSearch(jobs: Job[], query: string): Job[] {
    if (query.length <= 1)
        return jobs
    return new Fuse(jobs, {
        keys: [
            'customerName',
            'contactName',
            'phone',
            'email',
            'address.line1',
            'address.line2',
            'address.city',
            'address.zip',
            'notes',
            'equipment'
        ],
        minMatchCharLength: 2
    })
    .search(query)
    .map(r => r.item)
}